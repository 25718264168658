<template>
  <div class="assignment">
    <div class="main">
      <!-- <div class="title">
        <span @click="to_task_list">作业管理</span> / 布置作业
      </div> -->
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="作业名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="选择年级">
          <el-col :span="12">
            <el-select
              v-model="form.level_id"
              placeholder="请选择年级"
              @change="change_level">
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in level_list"
                :key="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择班级">
              <el-select v-model="form.class_id" placeholder="请选择班级">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in class_list"
                  :key="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-col :span="12">
            <el-date-picker
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期"
              v-model="form.start_time"
              style="width: 100%">
            </el-date-picker>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间">
              <el-date-picker
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期"
                v-model="form.end_time"
                style="width: 100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
      <div class="modules_box">
        <div class="top_box">
          <p>作业项目：</p>
          <div class="add_btn" @click="add_modules">添加项目</div>
        </div>
        <el-form
          :inline="true"
          v-for="(item, tindex) in project_list"
          :key="tindex"
          label-position="left">
          <el-form-item
            :label="val.name"
            v-for="(val, vindex) in item"
            :key="val.id"
            label-width="100px">
            <el-select
              v-if="vindex != 4"
              v-model="val.select"
              placeholder="请选择"
              @change="(val) => handleChangeDeptId(val, vindex, tindex)">
              <el-option
                :label="options.name"
                :value="options.id"
                v-for="(options, oindex) in val.list"
                :key="oindex">
              </el-option>
            </el-select>

            <el-select
              v-if="
                (vindex == 4 && item[2].select == 'TIME') ||
                (vindex == 4 && item[2].select == '')
              "
              v-model="val.select"
              placeholder="请选择"
              @change="(val) => handleChangeDeptId(val, vindex, tindex)">
              <el-option
                :label="options.name"
                :value="options.id"
                v-for="(options, oindex) in val.list"
                :key="oindex">
              </el-option>
            </el-select>

            <el-input
              v-if="vindex == 4 && item[2].select == 'COUNT'"
              v-model="val.select"
              placeholder="请输入">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn_box" @click="submit">立即发布</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newgroup_count: "",
      newtype: "",
      level_list: [],
      class_list: [],
      form: {
        name: "",
        level_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        items: [],
      },
      AI: [
        { id: "JUMP", name: "跳绳" },
        { id: "TRAMPOLINE", name: "蹦床" },
      ],
      CONVENTION: [
        { id: "GYMNASTICS", name: "跳操" },
        { id: "LONG_JUMP", name: "立定跳远" },
        { id: "RUN_STEP", name: "跑步" },
        { id: "BASKETBALL", name: "篮球" },
        { id: "SIT_UP", name: "仰卧起坐" },
        { id: "JUMPING", name: "开合跳" },
        { id: "KICKER", name: "踢键子" },
        { id: "SQUAT", name: "深蹲" },
        { id: "HIGH_KNEE", name: "高抬腿" },
        { id: "KICK_BUTTOCKS", name: "原地臀踢" },
      ],
      time_val: [
        { id: 60, name: "1分钟" },
        { id: 120, name: "2分钟" },
        { id: 180, name: "3分钟" },
      ],
      num_val: [
        { id: 100, name: "100" },
        { id: 200, name: "200" },
        { id: 300, name: "300" },
        { id: 400, name: "400" },
        { id: 500, name: "500" },
      ],
      project: [
        {
          id: "train",
          name: "运动训练模式",
          select: "",
          list: [
            // { id: "AI", name: "智能训练模式" },
            // { id: "CONVENTION", name: "常规训练模式" },
            // { id: "INTEREST", name: "趣味训练模式" },
          ],
        },
        {
          id: "sports_type",
          name: "运动类型",
          select: "",
          list: [],
        },
        {
          id: "mode",
          name: "运动模式",
          select: "",
          list: [
            // { id: "FREE", name: "自由" },
            { id: "COUNT", name: "计数" },
            { id: "TIME", name: "计时" },
          ],
        },
        {
          id: "group_count",
          name: "组数",
          select: "",
          list: [
            { id: 1, name: "1组" },
            { id: 2, name: "2组" },
            { id: 3, name: "3组" },
            { id: 4, name: "4组" },
            { id: 5, name: "5组" },
            { id: 6, name: "6组" },
            { id: 7, name: "7组" },
            { id: 8, name: "8组" },
            { id: 9, name: "9组" },
            { id: 10, name: "10组" },
          ],
        },
        {
          id: "per_group_val",
          name: "每组",
          select: "",
          list: [],
        },
      ],
      projectNun: [
        {
          id: "train",
          name: "运动训练模式",
          select: "",
          list: [],
        },
        {
          id: "sports_type",
          name: "运动类型",
          select: "",
          list: [],
        },
        {
          id: "mode",
          name: "运动模式",
          select: "",
          list: [
            // { id: "FREE", name: "自由" },
            { id: "COUNT", name: "计数" },
            { id: "TIME", name: "计时" },
          ],
        },
        {
          id: "group_count",
          name: "组数",
          select: "",
          list: [
            { id: 1, name: "1组" },
            { id: 2, name: "2组" },
            { id: 3, name: "3组" },
            { id: 4, name: "4组" },
            { id: 5, name: "5组" },
            { id: 6, name: "6组" },
            { id: 7, name: "7组" },
            { id: 8, name: "8组" },
            { id: 9, name: "9组" },
            { id: 10, name: "10组" },
          ],
        },
        {
          id: "per_group_val",
          name: "每组",
          select: "",
          list: [],
        },
      ],
      project_list: [
        [
          {
            id: "train",
            name: "运动训练模式",
            select: "",
            list: [
              // { id: "AI", name: "智能训练模式" },
              // { id: "CONVENTION", name: "常规训练模式" },
              // { id: "INTEREST", name: "趣味训练模式" },
            ],
          },
          {
            id: "sports_type",
            name: "运动类型",
            select: "",
            list: [],
          },
          {
            id: "mode",
            name: "运动模式",
            select: "",
            list: [
              // { id: "FREE", name: "自由" },
              { id: "COUNT", name: "计数" },
              { id: "TIME", name: "计时" },
            ],
          },
          {
            id: "group_count",
            name: "组数",
            select: "",
            list: [
              { id: 1, name: "1组" },
              { id: 2, name: "2组" },
              { id: 3, name: "3组" },
              { id: 4, name: "4组" },
              { id: 5, name: "5组" },
              { id: 6, name: "6组" },
              { id: 7, name: "7组" },
              { id: 8, name: "8组" },
              { id: 9, name: "9组" },
              { id: 10, name: "10组" },
            ],
          },
          {
            id: "per_group_val",
            name: "每组",
            select: "",
            list: [],
          },
        ],
      ],
      submit_item: {
        train: "",
        sports_type: "",
        per_group_val: "",
        group_count: "",
        mode: "",
      },
      submit_items: [
        {
          train: "",
          sports_type: "",
          per_group_val: "",
          group_count: "",
          mode: "",
        },
      ],
      sport_categorize: [],
    };
  },
  created() {
    this.get_level();
    this.handlesport_categorize();
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    // this.form.end_time = this.moment(date).format("YYYY-MM-DD HH:mm");
    this.form.end_time = Number(date);
    this.form.start_time = Number(new Date());
    console.log(this.form.start_time);

    this.form.name =
      this.moment(this.form.start_time).format("YYYY-MM-DD ") + "体育作业";
    // this.form.form=
  },
  methods: {
    async handlesport_categorize() {
      const res = await this.axios.get(`/sport_categorize`);
      if (res.code === 1) {
        console.log(res, "-task_name");
        let list = [];
        res.data.forEach((item) => {
          list.push({
            id: item.id,
            name: item.value,
          });
        });
        let first = this.project_list[0][0];
        first.list = list;
        this.project[0].list = list;
        this.projectNun[0].list = list;
        console.log(this.project[0].list, "this.project_list[0][0]");
        this.sport_categorize = res.data;
        console.log(this.sport_categorize);

        console.log(this.project, "-project11111111");
        console.log(list, "-list11111111");
      }
    },
    onSubmit() {},
    add_modules() {
      // console.log(this.project_list, "- this.project_list");
      // console.log(this.project[0], "- this.project[0][0]1111");

      // this.project_list.push(JSON.parse(JSON.stringify(this.project)));

      // console.log(this.submit_item, "submit_items");
      let project = JSON.parse(JSON.stringify(this.projectNun));
      console.log(project);
      this.project_list.push(project);
      let submit = {
        train: "",
        sports_type: "",
        per_group_val: "",
        group_count: "",
        mode: "",
      };
      this.submit_items.push(submit);
      console.log(this.project_list, "project_list");
    },

    async get_level() {
      const res = await this.axios.get("/level/classes");
      if (res.code === 1) {
        this.level_list = res.data;
        this.class_list = this.level_list[0].classes;
        this.form.level_id = this.level_list[0].id;
        this.form.class_id = this.level_list[0].classes[0].id;
      }
    },
    change_level(val) {
      this.level_list.some((item) => {
        if (item.id === val) {
          this.class_list = item.classes;
          return true;
        }
      });
    },
    handleChangeDeptId(val, vindex, tindex) {
      console.log(val, vindex, tindex);
      // if (vindex === 0 && val === "AI") {
      //   this.project_list[tindex][1].list = this.AI;
      // } else if (vindex === 0 && val === "CONVENTION") {
      //   this.project_list[tindex][1].list = this.CONVENTION;
      // }

      if (vindex == 0) {
        this.sport_categorize.forEach((item) => {
          if (val == item.id) {
            console.log(item);
            this.project_list[tindex][1].list = item.child.map((i) => {
              return {
                id: i.name,
                name: i.value,
              };
            });
          }
        });
        this.project_list[tindex][1].select = "";
      }
      if (vindex == 2 && val == "TIME") {
        this.newtype = "TIME";
        this.project_list[tindex][4].list = this.time_val;
      } else if (vindex == 2) {
        this.newtype = "COUNT";
        this.project_list[tindex][4].list = this.num_val;
      }
      //
    },
    async submit() {
      let list = JSON.parse(JSON.stringify(this.project_list));
      for (let i = 0; i < list.length; i++) {
        this.submit_items[i].train = list[i][0].select;
        this.submit_items[i].sports_type = list[i][1].select;
        this.submit_items[i].mode = list[i][2].select;
        this.submit_items[i].group_count = list[i][3].select;
        if (this.newtype == "TIME") {
          this.submit_items[i].per_group_val = list[i][4].select;
        } else {
          this.submit_items[i].per_group_val = list[i][4].select;
        }
      }

      this.form.items = this.submit_items;
      let data = JSON.parse(JSON.stringify(this.form));
      data.start_time = data.start_time / 1000;
      data.end_time = data.end_time / 1000;
      const res = await this.axios.post("/homework", {
        row: data,
      });
      if (res.code === 1) {
        this.$alert("发布成功", "温馨提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            this.$router.push({ path: "/tasklist" });
          },
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    to_task_list() {
      this.$router.push({ path: "/tasklist" });
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.assignment {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
  .title {
    font-size: 20px;
    font-weight: normal;
    color: #5fa7ff;
    span {
      color: #999999;
      cursor: pointer;
    }
  }
  .el-form {
    // margin-top: 40px;
    .el-select {
      width: 100%;
    }
  }
  .modules_box {
    padding: 10px;
    padding-left: 20px;
    border-radius: 10px;
    background: #f2f2f2;
    .top_box {
      display: flex;
      font-size: 15px;
      justify-content: space-between;

      .add_btn {
        width: 100px;
        height: 34px;
        background: #ffffff;
        border-radius: 5px;
        border: 1px solid #5fa7ff;
        color: #5fa7ff;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
      }
    }
    .el-form-item {
      margin-right: 40px;
    }
  }
  .btn_box {
    width: 400px;
    height: 43px;
    background: linear-gradient(142deg, #89e2fe 0%, #63a4ff 100%);
    box-shadow: 0px 3px 30px -5px rgba(111, 143, 234, 0.46);
    border-radius: 24px;
    color: #fff;
    text-align: center;
    line-height: 43px;
    font-size: 20px;
    // position: absolute;
    // bottom: 20px;
    // left: 50%;
    // transform: translate(-50%);
    cursor: pointer;
    margin: 0 auto;
    margin-top: 30px;
  }
}
</style>
